import axios from 'axios';
import { Box } from '@mui/material';
import authApi from '../api';
import { useUserAuth } from '../context/AuthProvider';
// import Header from '../pages/Header';
import React, { useEffect, useState } from 'react';
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// import goldChain from '../assets/goldChain.jpg';
// import goldRing from '../assets/goldRing.jpg';
// import { Container, Row, Col } from "react-bootstrap";
// import Carousel from 'react-bootstrap/Carousel';
// import 'bootstrap/dist/css/bootstrap.css';
// import logo from '../assets/plusLogo49.png'
// import '../App.css';
// import Header from '../pages/Header';
import { useNavigate } from "react-router-dom";
import { InputBase } from "@mui/material";
import { Typography, IconButton, Button } from "@mui/material";
// import axios from 'axios';
// import { Next } from 'react-bootstrap/esm/PageItem';
import OtpInput from 'react-otp-input';

function Login() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();
  const context = useUserAuth();
const [errorOtp, setOtpError] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isOtpValue, setIsOtpValue] = useState(false);
  const [otpValid, setOtpvalid] = useState("");
  const [otpValidationError, setOtpValidationError] = useState("");
  const [isValidOTP, setIsValidOTP] = useState(true);

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setIsDirty(true);
    setPhoneNumber(value);

    // Define a regular expression for a valid phone number pattern
    const phonePattern = /^[6-9][0-9]{9}$/; // Change this pattern according to your needs

    // Check if the entered phone number matches the pattern
    if (phonePattern.test(value)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  // const handlePhoneNumberChange = (event) => {
  //   setPhoneNumber(event.target.value);
  // };
  const handleOtpChange = (otpValue) => {
    setIsOtpValue(true);
    // Check if otpValue contains only numeric characters
    if (/^\d*$/.test(otpValue)) {
      setOtp(otpValue);
      setIsValidOTP(true);
    } else {
      // console.error('Invalid input. Please enter only numeric characters.');
      setIsValidOTP(false);
      setOtpValidationError("Enter Valid OTP")
    }
  };

  const handleSendOtp = async () => {
    const response = await authApi.getOtp({ phone_number: phoneNumber });
    if (response.status === 200) {
      setOtpSent(true);
    }else if (response.status === 400){
      // setOtpError(response.detail);
      // console.log(response.detail);
    } else {
      console.error('Failed to send OTP');
    }
    // console.log(response, phoneNumber, "reasdsdff");
  };
  const handleLogin = async () => {
    const response = await authApi.logIn({ phone_number: phoneNumber, otp: otp, new_user: false, })
    // const data = await response.json();

   
    // console.log(response);
    if (response.access_token) {
      context?.onLogin(response.access_token);
      // navigate('/dashboard');
      navigate('/home');
    }else if(response.error){
      // console.log(message)
      setOtpError("Enter a valid OTP or phone number");
      // console.log(data.detail);
    }else{
      navigate('/');
    }
    // console.log(response, phoneNumber, otp, "reasdsdff");
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('auth');
    if (isLoggedIn) {
      navigate('/home');
      // console.log(isLoggedIn);
    }else{
      navigate('/');
    }
  }, []);

  return (
    <>
        <Box backgroundColor={"#1F2A40"} padding={"80px"} borderRadius={"20px"} margin={"20px"}>
        <Box display="flex" justifyContent="center" alignItems="center">
                        <img 
                        alt='profile-user'
                        width="100px"
                        height="100px"
                        src={`../../assets/plusLogo.jfif`}
                        style={{cursor:"pointer", borderRadius:"50%"}}
                        />
                    </Box>
          <Box>
            <h1 style={{fontSize:"35px", textAlign: "center", color:"#3da58a"}}>Customer Success Dashboard</h1>
          </Box>
          <Box>
            <h2 style={{ textAlign: "center", color:"#fff" }}>User&nbsp;Login&nbsp;with&nbsp;OTP</h2>
            <div  style={{ marginBottom: "10px", textAlign: "center" }}>
              <label style={{ textAlign: "center", color:"#fff", fontSize:"15px", fontWeight:"800", paddingBottom:"50px" }} >Phone&nbsp;Number</label>
              <Box
                display="flex"
                backgroundColor="#fff"
                color="#000 !important"
                // borderRadius="3px"
                sx={{
                  "& .css-156xqnd-MuiInputBase-input": {
                    color: "#000 !important"
                    },
                    "& .css-1jhxu0 ":{
                      color: "#000 !important"
                     }
                }}
              >
                <InputBase
                  sx={{ ml: 2, flex: 1, border: "#fff solid 1px", textAlign:"center" }}
                  type="text"
                  id="phoneNumber"
                  placeholder="Enter Your Phone Number"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                />
                
              </Box>
              <Box>
              {!isValid && isDirty && (
        <p style={{ color: 'red' }}>
          Enter your registered phone number
        </p>
      )}
              </Box>
            </div>

            {otpSent ? (
              <Box textAlign={"center"}>
                <label style={{ textAlign: "center", color:"#fff", fontSize:"15px", fontWeight:"800", marginBottom:"5px"  }}>OTP</label>
                <div style={{ marginBottom: "10px" }}>
                  <Box
                    display="flex"
                    justifyContent={"center"}
                    // backgroundColor="#fff"
                    color="#000 !important"
                    // borderRadius="3px"
                    sx={{
                      "& .css-156xqnd-MuiInputBase-input": {
                        color: "#000 !important"
                        },
                       "& .css-1jhxu0 ":{
                        color: "#000 !important"
                       }
                    }}
                  >
                    {/* <InputBase
                      sx={{ ml: 2, flex: 1, border: "#fff solid 1px", textAlign:"center"
                    
                    }}
                      type="text"
                      placeholder="Enter Your OTP"
                      id="otp"
                      value={otp}
                      onChange={handleOtpChange}
                    /> */}
                  
                  <OtpInput
  value={otp}
  id="otp"
  onChange={handleOtpChange}
  numInputs={4}
  separator={<span></span>}
  renderInput={(props, index) => (
    <input
      {...props}
      key={index}
      maxLength={1} // Assuming you want a one-digit input
    />
    
  )}
  isInputNum
  shouldAutoFocus
  containerStyle={{ display: 'flex', justifyContent: 'space-between' }}
  inputStyle={{
    width: '40px', // Adjust the width as needed
    height: '40px', // Adjust the height as needed
    fontSize: '20px', // Adjust the font size as needed
    margin: '5px', // Adjust the margin as needed
    textAlign: 'center', // Center the text
    borderRadius:"5px"
  }}
/>
        </Box>
        <div>
{!isValidOTP && (
        <p style={{ color: 'red' }}>
          Enter valid OTP
        </p>
      )}
         </div> 
                  <Box>
                   <p style={{ color: 'red' }}> {errorOtp}</p>
                  </Box>
                </div>
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Button variant='contained' color='success' onClick={handleLogin}>Login</Button>
                </Box>
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
                <Button variant='contained' color='warning' onClick={handleSendOtp}>Resend&nbsp;OTP</Button>
              </Box>
              </Box>
            ) : (
              <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
                <Button variant='contained' color='success' onClick={handleSendOtp}>Send&nbsp;OTP</Button>
              </Box>
            )}
          </Box>
        </Box>
    </>


  );
}
export default Login;
